@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap);
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style: none;
}

:root {
  --text: #333333;
  --text-lighter: #4F4F4F;
  --text-light: #e6e6e6;
  --dark-gray: #4f4f4f;
  --darkest-pink: #f564ac;
  --dark-pink: #efadce;
  --pink: #f5bfda;
  --accent-color: #c484ca;
  --main-color: #FCEDF2;
  --top-index: 500;
  --medium-index: 400;
  --lower-index: 300;
  --lowest-index: 200;
}

a {
  color: white;
}

a, p {
  font-size: 21px;
}

img {
  display: block;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Lato', sans-serif;
  color: #333333;
  color: var(--text);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* --- TABLET AND MOBILE --- */
@media (max-width: 830px) {
  a, p{
    font-size: 16px;
  }
}
